import { AwsEmailDnsProvider } from '../AwsEmailDnsProvider'

export default {
  name: 'StepTwo',
  props: {
    domainObject: { type: Object, require: true },
  },
  components: {},
  data: function () {
    return {
      showMXHelp: false,
      showTXTHelp: false,
      showDKIMHelp: false,
    }
  },
  watch: {},
  mounted () {
    console.log('domainObject', this.domainObject)
  },
  computed: {
    canNrsSmtp () {
      return this.$store.getters['auth/getUser'].enable?.nrssmtp
    },
    canEmailV2 () {
      return this.$store.getters['auth/getUser'].enable?.emailv2
    },
    canEmailV1 () {
      return this.$store.getters['auth/getUser'].enable?.emailv1
    },
    isRoute53 () {
      return this.domainObject.domain.dnsProvider === AwsEmailDnsProvider.ROUTE53_ID
    },
  },
  methods: {
    emitDownloadPdf () {
      this.$emit('downloadPdf')
    },
    stripName (txt) {
      if (txt === this.domainObject.domain.domain) {
        return '@'
      } else {
        return txt.replace(new RegExp('.' + this.domainObject.domain.domain + '$'), '')
      }
    },
  },
}
